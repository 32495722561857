import { A, pipe } from '@mobily/ts-belt';
import type { BasicCloudinaryAsset } from '../../utils/getCloudinaryItem/getCloudinaryItem';

export { type BasicCloudinaryAsset } from '../../utils/getCloudinaryItem/getCloudinaryItem';

/**
 * We now host a CloudFront distribution in front of cloudinary that has support for f_auto
 * (by using the Accept header as part of the cache key). In order to reduce cost, we should
 * use this CloudFront distribution to serve images.
 * For more: https://stitchfix.atlassian.net/wiki/spaces/KF/pages/5275451406/Support+for+Cloudinary+f_auto+Optimizations+WIP
 */
const cloudinaryPath = 'https://d3n78nkjl8tizo.cloudfront.net/stitch-fix';
const defaultResponsiveSizes = A.range(1, 10).map(i => i * 200);

type BaseArgs = {
  cloudinaryAsset: BasicCloudinaryAsset;
  cloudinaryAction?: 'upload' | 'fetch';
};

type GetSrcFunc = (
  args: BaseArgs & {
    size?: number;
  },
) => string;

export const getSrc: GetSrcFunc = ({
  cloudinaryAsset: { path },
  size,
  cloudinaryAction = 'upload', // default to 'upload
}) => {
  const fileExtension = path.split('.').pop();

  const transforms = fileExtension === 'svg' ? 'q_auto' : 'f_auto,q_auto';

  return `${cloudinaryPath}/image/${cloudinaryAction}/${transforms}${
    size ? `,w_${size}` : ''
  }/${path}`;
};

type GetSrcSetItemFunc = (
  args: BaseArgs & {
    size: number;
  },
) => string;

export const getSrcSetItem: GetSrcSetItemFunc = ({
  cloudinaryAsset: cloudinaryField,
  cloudinaryAction,
  size,
}) =>
  `${getSrc({
    cloudinaryAsset: cloudinaryField,
    size,
    cloudinaryAction,
  })} ${size}w`;

type GetSrcSetFunc = (args: BaseArgs) => string;

export const getSrcSet: GetSrcSetFunc = ({
  cloudinaryAsset: cloudinaryField,
  cloudinaryAction,
}) => {
  let responsiveSizes = defaultResponsiveSizes.filter(
    size => size <= cloudinaryField.width, // make sure we're not upscaling unnecessarily
  );

  // if the image is smaller than the smallest responsive size, we'll just use the image's width
  if (responsiveSizes.length === 0) {
    responsiveSizes = [cloudinaryField.width];
  }

  return responsiveSizes
    .map(size =>
      getSrcSetItem({
        cloudinaryAsset: cloudinaryField,
        cloudinaryAction,
        size,
      }),
    )
    .join(', ');
};
/**
 * Relying on CSS to hide images at a given screen size does not prevent the browser from requesting the image.
 * In the picture element, we can specify a base64 encoded 1px image as an image source to prevent the browser
 * from requesting an actual Cloudinary image at a given screen size.
 * Source: https://medium.com/@mike_masey/how-to-use-the-picture-element-to-prevent-images-loading-on-mobile-devices-1376e33b190e
 */
const onePixelImage =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

type PictureSrcBaseArgs = {
  cloudinaryAsset?: BasicCloudinaryAsset;
};

export const getPictureSrc = ({ cloudinaryAsset }: PictureSrcBaseArgs) =>
  cloudinaryAsset ? getSrc({ cloudinaryAsset }) : onePixelImage;

export const getPictureSrcSet = ({ cloudinaryAsset }: PictureSrcBaseArgs) =>
  cloudinaryAsset ? getSrcSet({ cloudinaryAsset }) : `${onePixelImage} 1w`;

const formatMediaQuery = ({
  minWidth,
  resolution,
  cssValue,
}: {
  resolution: number;
  minWidth: number;
  cssValue: string;
}) => {
  const mediaQuery = [
    minWidth > 0 && `(min-width: ${minWidth}px)`,
    resolution > 1 && `(min-resolution: ${resolution}x)`,
  ]
    .filter(Boolean)
    .join(' and ');

  return [mediaQuery, cssValue].join(' ').trimStart();
};

/**
 * Config that indicates a `minWidth` media query directive in pixels
 * and a percent of the screenwidth to indicate layout at that media query.
 * Example: `{ minWidth: 900, fluidWidth: 50 }`
 */
type FluidSize = { minWidth: number; fluidWidth: number };
/**
 * Config that indicates a `minWidth` media query directive in pixels
 * and the desired fixed pixel width of the image.
 * Example: `{ minWidth: 900, staticWidth: 200 }`
 */
type StaticSize = { minWidth: number; staticWidth: number };

export type SizeConfig = FluidSize | StaticSize;

type DprConfig = {
  resolution: number;
  reduction: number;
};

type FormatFluidSizeArguments = DprConfig & {
  size: FluidSize;
  maxContainerWidth: number;
  isFirst: boolean;
};

export const formatFluidSize = ({
  size: { minWidth, fluidWidth },
  resolution,
  reduction,
  maxContainerWidth,
  isFirst,
}: FormatFluidSizeArguments) => {
  const normalizedViewWidth = fluidWidth * reduction;
  const baseMediaQuery = formatMediaQuery({
    minWidth,
    resolution,
    cssValue: `${Math.round(normalizedViewWidth)}vw`,
  });

  /**
   * if it is the first size and we have a maxContainerWidth set
   * prepend a capping size that has a pixel value instead of a
   * relative value
   */
  if (isFirst && maxContainerWidth) {
    /**
     * When the viewport is > than the layout container's max-width,
     * we need to set the image size to be _relative_ to the size of the container
     * to prevent the browser from loading unncessarily large images
     * e.g., if an image displayed in a 800px 4-column grid passes a `viewWidth: 25`,
     * the browser should load a 200px image on all viewports >= 800px,
     * as opposed to a 500px image on a 2000px screen for example
     */
    const maxContainerMediaQuery = formatMediaQuery({
      resolution,
      minWidth: maxContainerWidth,
      cssValue: `${Math.round(
        maxContainerWidth * (normalizedViewWidth / 100),
      )}px`,
    });

    return [maxContainerMediaQuery, baseMediaQuery].join(', ');
  }

  return baseMediaQuery;
};

type FormatStaticSizeArguments = DprConfig & {
  size: StaticSize;
};

export const formatStaticSize = ({
  size: { minWidth, staticWidth },
  resolution,
  reduction,
}: FormatStaticSizeArguments) => {
  const normalizedViewWidth = staticWidth * reduction;

  return formatMediaQuery({
    minWidth,
    resolution,
    cssValue: `${Math.round(normalizedViewWidth)}px`,
  });
};

type NormalizeSizeArguments = DprConfig & {
  sizes?: SizeConfig[];
  maxContainerWidth: number;
};

const isFluidSize = (size: SizeConfig): size is FluidSize =>
  'fluidWidth' in size;

const normalizeSizes = ({
  sizes = [{ minWidth: 0, fluidWidth: 100 }],
  resolution,
  reduction,
  maxContainerWidth,
}: NormalizeSizeArguments) =>
  pipe(
    sizes,
    A.uniqBy(size => size.minWidth), // remove duplicate screen sizes, keeps first instance in array
    A.sort((sizeA, sizeB) => sizeB.minWidth - sizeA.minWidth), // sort in descending order
    A.mapWithIndex((index, size) => {
      if (isFluidSize(size)) {
        return formatFluidSize({
          size,
          resolution,
          reduction,
          maxContainerWidth,
          isFirst: index === 0,
        });
      }

      return formatStaticSize({ size, reduction, resolution });
    }),
    A.join(', '),
  );

/**
 * definition of commonly used layout max widths
 * this will allow us to change things centrally
 * if need be.
 */
const layoutMaxWidth = {
  xl: 2000,
  lg: 1600,
  md: 1400,
  sm: 1200,
  xs: 800,
  fullBleed: 0,
};

export type MaxContainerWidth = number | keyof typeof layoutMaxWidth;

export type GetSizesConfig = {
  sizes?: SizeConfig[];
  dprs?: DprConfig[];
  /**
   * maximum container width in pixels, 0 is a stand-in for full bleed
   * or infinite width.
   *
   * common values are used for hinting and consistency.
   */
  maxContainerWidth?: MaxContainerWidth;
};

/**
 * Left to it's own devices, srcset is very intelligent and will generally
 * pick the right size based upon a simple size directive, for example:
 *
 * ```html
 * <img sizes="(min-width: 900px) 33vw, 100vw" ... />
 * ```
 *
 * When trying to deliver the smallest asset possible across screen size and device
 * we run in to problems:
 *
 * - High-DPR devices (e.g. iPhone) will request _very_ large images, requiring us
 *   to essentially cap DPR at 2x
 * - Our layouts are not fully flexible and have a max-width, so we we also need to
 *   cap the largest image request on large screen sizes
 *
 * Using a combination of sizes (that contain a min-width for a media query and a number for a vw value)
 * and the `maxContainerWidth` we can create a `sizes` definition that is better optimized
 * to deliver the smallest image possible.
 */
export const getSizes = ({
  sizes,
  dprs = [],
  maxContainerWidth = 2000,
}: GetSizesConfig) =>
  pipe(
    [...dprs, { resolution: 1, reduction: 1 }],
    A.uniqBy(dpr => dpr.resolution), // remove duplicate screen sizes, keeps first instance in array
    A.sort((dprA, dprB) => dprB.resolution - dprA.resolution), // sort in descending order
    A.map(dpr =>
      normalizeSizes({
        sizes,
        maxContainerWidth:
          typeof maxContainerWidth === 'string'
            ? layoutMaxWidth[maxContainerWidth]
            : maxContainerWidth,
        ...dpr,
      }),
    ),
    A.join(', '),
  );

/**
 * Matches URLs that contain a `/v{version}/` segment, which is a Cloudinary convention
 */
const cloudinaryImagePattern = /\/v\d+\/(.+)$/;

const isCloudinaryImageUrl = (imageUrl: string) =>
  imageUrl.match(cloudinaryImagePattern);

export const getCloudinaryAction = (imageUrl: string) =>
  isCloudinaryImageUrl(imageUrl) ? 'upload' : 'fetch';

export const formatImageUrl = (imageUrl: string) => {
  if (isCloudinaryImageUrl(imageUrl)) {
    // match the first part of the URL (protocol and domain) and the optional `stitch-fix/image/upload/` segment
    const urlPrefixPattern =
      /^https?:\/\/[^/]+\/?(stitch-fix\/image\/upload\/)?/;

    return imageUrl.replace(urlPrefixPattern, '');
  }

  return imageUrl;
};
